import React, { useState } from "react";
import "./styles/Dropdown.css";
import { FaChevronDown } from "react-icons/fa";

const Dropdown = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dropdown">
      <button className="dropdown-title" onClick={toggleDropdown}>
        {title}{" "}
        <FaChevronDown className={`dropdown-icon ${isOpen ? "open" : ""}`} />
      </button>
      <div className={`dropdown-content ${isOpen ? "show" : ""}`}>
        {children}
      </div>
    </div>
  );
};

export default Dropdown;
