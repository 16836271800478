import React from "react";
import "./styles/HeroSection.css";
import Button from "../Components/Button";
import AvatarYoussef from "../images/youssef-benkirane.png";
import AvatarLouis from "../images/louis-blondot.png";
import LogoEpita from "../images/logo-epita.png";
import LogoSigl from "../images/logo-sigl.png";

const HeroSection = () => {
  return (
    <section className="hero light-text">
      <div className="hero-container">
        <div className="hero-content">
          <div className="hero-text">
            <h1 className="title">
              Projet Equivalence <br />
              <span className="text-accent">Siweb 2023</span>
            </h1>
            <h2 className="sub-title">
              Développer un <span className="text-accent">Annuaire SIGL</span>
            </h2>
            <div className="body-text hero-authors">
              <p>Par Youssef Benkirane & Louis Blondot</p>
              <div className="hero-authors-avatars">
                <img src={AvatarYoussef} alt="Youssef Benkirane" />
                <img src={AvatarLouis} alt="Louis Blondot" />
              </div>
            </div>
          </div>
          <img src={LogoEpita} alt="Logo Epita" className="hero-illustration" />
          <img src={LogoSigl} alt="Logo SIGL" className="hero-illustration" />
        </div>

        <div className="hero-buttons">
          <Button
            text={"En savoir plus sur le projet ! "}
            link={"#presentation-projet"}
          />
          <Button
            isSeconadry={true}
            text={"Lire la Documentation"}
            link={"#documentation-dev"}
          />
          <Button
            isSeconadry={true}
            text={"Voir la Démonstration"}
            link={"#demonstration-annuaire"}
          />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
