import React, { useState, useEffect } from "react";
import axios from "axios";
import SiglTable from "../Components/TableSigl";
import { getAllSigl, searchSigl, swaggerDemo } from "../AnnuaireSiglApi";
import "./styles/DemonstrationAnnuaire.css";
import Button from "../Components/Button";
import SeachSiglForm from "../Components/SeachSigl";

const DemonstrationAnnuaire = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(getAllSigl)
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, []);

  const handleFormSubmit = (values) => {
    setIsLoading(true);
    axios
      .post(searchSigl, values)
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching search results:", error);
        setIsLoading(false);
      });
  };

  return (
    <div className="container">
      <section className="demonstration-annuaire" id="demonstration-annuaire">
        <div className="demonstration-annuaire-intro">
          <h1 className="title">Démonstration Module Annuaire SIGL</h1>
          <Button text={"Intéragir avec l'API"} link={swaggerDemo} />
        </div>

        <h2 className="sub-title">Rechercher un Alumni SIGL</h2>

        <SeachSiglForm onSubmit={handleFormSubmit} />

        {isLoading ? <p>Loading...</p> : <SiglTable data={data} />}
      </section>
    </div>
  );
};

export default DemonstrationAnnuaire;
