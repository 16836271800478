// Feature card have an image, a title, a description
// A sub part of title should be highlighted

import React from "react";
import "./styles/FeatureCard.css";

const FeatureCard = ({ children }) => {
  return <div className="feature-card">{children}</div>;
};

export default FeatureCard;
