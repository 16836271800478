import React, { useState, useEffect } from "react";
import "./styles/TableSigl.css";
import "./styles/Pagination.css";
import ReactPaginate from "react-paginate";

const TableSigl = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(10);

  useEffect(() => {
    setCurrentPage(0);
    console.log(data);
  }, [data]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * perPage;
  const pageCount = Math.ceil(data.length / perPage);

  return (
    <div className="table-sigl">
      <table>
        <thead>
          <tr>
            <th>Nom</th>
            <th>Métier</th>
            <th>Entreprise</th>
            <th>Promotion SIGL</th>
            <th>Profil Linkedin</th>
            <th>Profil CRI</th>
            <th>Email EPITA</th>
          </tr>
        </thead>
        <tbody>
          {data.slice(offset, offset + perPage).map((sigl) => (
            <tr key={sigl.id}>
              <td>{sigl.fullName}</td>
              <td>{sigl.job}</td>
              <td>{sigl.company}</td>
              <td>{sigl.promotionSIGL}</td>
              <td>
                {sigl.profileUrl ? (
                  <a href={sigl.profileUrl}>Linkedin</a>
                ) : (
                  <span></span>
                )}
              </td>
              <td></td>
              <td>
                {sigl.criProfileUrl ? (
                  <a href={sigl.criProfileUrl}>CRI</a>
                ) : (
                  <span></span>
                )}
              </td>
              <td>
                {sigl.emailEpita ? (
                  <a href={sigl.emailEpita}>Email</a>
                ) : (
                  <span></span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactPaginate
        previousLabel={"←"}
        nextLabel={"→"}
        pageCount={pageCount}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
    </div>
  );
};

export default TableSigl;
