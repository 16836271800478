// This component take a percentage as props and display a progress bar

import React from "react";
import "./styles/ProgressBar.css";

const ProgressBar = ({ percentage }) => {
  return (
    <div className="progress-bar">
      <div
        className="progress-bar-fill"
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
