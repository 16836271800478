/* La documentation Ops comprends plusieurs sections :
1. Les containers Docker
2. La CI Github Actions
3. Se connecter au serveur
4. Déployer le projet
*/

import React from "react";
import CodeSnippet from "../Components/CodeSnippet";
import "./styles/DocumentationOps.css";

const DocumentationOps = () => {
  return (
    <section className="documentation-ops" id="documentation-ops">
      <div className="container">
        <div className="documentation-ops-wrapper">
          <h2 className="title">Documentation pour les Ops</h2>
          <div className="documentation-ops-content">
            <div className="documentation-ops-section">
              <h3 className="sub-title text-accent-dark">Containers Docker</h3>
              <p className="body-text">Le projet est composé de 3 services :</p>
              <ul className="inline-list">
                <li>API annuaire-sigl</li>
                <li>Frontend</li>
                <li>Base de données PostgreSQL</li>
              </ul>
              <p className="body-text">
                Les services sont définis dans le fichier docker-compose.yml à
                la racine du projet.
              </p>
            </div>
            <div className="documentation-ops-section">
              <h3 className="sub-title text-accent-dark">CI Github Actions</h3>
              <p className="body-text">
                Le projet est configuré avec une CI Github Actions. Le fichier
                de configuration est situé dans le dossier .github/workflows.
              </p>
              <p className="body-text">
                Le workflow est déclenché à chaque push sur la branche master.
                Il vérifie que l'application est fonctionnelle et déploie le
                projet sur le serveur de production.
              </p>
              <p className="body-text">
                Deux packages sont généré par le workflow :
              </p>
              <ul className="inline-list">
                <li>
                  <a
                    className="link"
                    href="https://github.com/SIGL-SIWEB/annuaire/pkgs/container/annuaire-frontend"
                  >
                    annuaire-frontend
                  </a>
                </li>
                <li>
                  <a
                    className="link"
                    href="https://github.com/SIGL-SIWEB/annuaire/pkgs/container/annuaire-backendapi"
                  >
                    annuaire-backendapi
                  </a>
                </li>
              </ul>
            </div>
            <div className="documentation-ops-section">
              <h3 className="sub-title text-accent-dark">
                Se connecter au serveur
              </h3>
              <p className="body-text">
                Pour se connecter au serveur, il faut utiliser la commande ssh
                suivante :
              </p>
              <CodeSnippet
                code={`> ssh -J bastion@91.243.117.217:35334 root@192.168.0.143`}
              />
              <p className="body-text">
                Assurez-vous d'avoir les droits d'accès au serveur auprès de
                SiOps
              </p>
            </div>
            <div className="documentation-ops-section">
              <h3 className="sub-title text-accent-dark">Déployer le projet</h3>
              <p className="body-text">
                Pour déployer le projet, il faut se connecter au serveur et
                exécuter les commandes suivantes :
              </p>
              <CodeSnippet
                code={`> docker-compose pull
> docker-compose up -d`}
              />
              <p className="body-text">
                La commande docker-compose pull permet de récupérer les
                dernières versions des images Docker.
              </p>
              <p className="body-text">
                La commande docker-compose up -d permet de démarrer les services
                du projet.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DocumentationOps;
