// Main feature sections have 4 feature cards

import React from "react";
import "./styles/MainFeaturesSection.css";
import FeatureCard from "../Components/FeatureCard";
import DockerIllustration from "../images/docker-illustration.png";
import SiglsIllustration from "../images/sigls-illustration.png";
import SearchIllustration from "../images/search-illustration.png";
import DashboardIllustration from "../images/dashboard-illustration.png";

const MainFeaturesSection = () => {
  return (
    <section className="main-features" id="main-features">
      <div className="container">
        <div className="main-features-cards">
          <FeatureCard>
            <img src={DockerIllustration} alt="Docker" />
            <h3 className="sub-title">
              Applications Web
              <br />
              <span className="text-accent-dark">Dockerisé</span>
            </h3>
            <p className="body-text">
              Pour améliorer l'expérience développeurs des futures équipes, nous
              avons dockeriser toutes les Applications
            </p>
          </FeatureCard>

          <FeatureCard>
            <img src={SiglsIllustration} alt="Sigls" />
            <h3 className="sub-title">
              850+ profils
              <br />
              <span className="text-accent-dark">Alumni SIGL</span>
            </h3>
            <p className="body-text">
              Que ce soit depuis Linkedin ou depuis le CRI, nous avons récupéré
              plus de 850 profils SIGL.
            </p>
          </FeatureCard>

          <FeatureCard>
            <img src={SearchIllustration} alt="Search" />
            <h3 className="sub-title">
              Filtres de
              <br />
              <span className="text-accent-dark">Recherche</span>
            </h3>
            <p className="body-text">
              Pour pouvoir trouver facilement un élève en fonction de son nom,
              son job, son entreprise ou sa promotion.
            </p>
          </FeatureCard>

          <FeatureCard>
            <img src={DashboardIllustration} alt="Dashboard" />
            <h3 className="sub-title">
              Dashboard
              <br />
              <span className="text-accent-dark">Administrateur</span>
            </h3>
            <p className="body-text">
              Le dashboard permet de suivre l’évolution du projet et d’en
              apprendre plus sur le parcours des alumnis.
            </p>
          </FeatureCard>
        </div>
      </div>
    </section>
  );
};

export default MainFeaturesSection;
