import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  getSiglCount,
  getLinkedinProfileCount,
  getCriProfileCount,
  getValidJobsCount,
  getValidCompaniesCount,
  getMostPopularJob,
  getMostPopularCompanies,
  swaggerDemo,
} from "../AnnuaireSiglApi";

import "./styles/DemonstrationDashboard.css";

import MostPopularJobChart from "../Components/MostPopularJobChart";
import MostPopularCompaniesTable from "../Components/MostPopularCompaniesTable";
import ProgressBar from "../Components/ProgressBar";
import Button from "../Components/Button";

const DemonstrationDashboard = () => {
  const [siglCount, setSiglCount] = useState(0);
  const [linkedinProfileCount, setLinkedinProfileCount] = useState(0);
  const [criProfileCount, setCriProfileCount] = useState(0);
  const [validJobsCount, setValidJobsCount] = useState(0);
  const [validCompaniesCount, setValidCompaniesCount] = useState(0);
  const [mostPopularJob, setMostPopularJob] = useState([]);
  const [mostPopularCompanies, setMostPopularCompanies] = useState([]);

  useEffect(() => {
    axios
      .get(getSiglCount)
      .then((response) => {
        setSiglCount(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(getLinkedinProfileCount)
      .then((response) => {
        setLinkedinProfileCount(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(getCriProfileCount)
      .then((response) => {
        setCriProfileCount(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(getValidJobsCount)
      .then((response) => {
        setValidJobsCount(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(getValidCompaniesCount)
      .then((response) => {
        setValidCompaniesCount(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(getMostPopularJob)
      .then((response) => {
        setMostPopularJob(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(getMostPopularCompanies)
      .then((response) => {
        setMostPopularCompanies(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="container">
      <section className="demonstration-dashboard" id="demonstration-dashboard">
        <div className="demonstration-annuaire-intro">
          <h1 className="title">Démonstration Module Dashboard</h1>
          <Button text={"Intéragir avec l'API"} link={swaggerDemo} />
        </div>
        <div className="demonstration-dashboard-kpis-section">
          <h2 className="sub-title text-accent-dark">KPIs du projet</h2>
          <div className="demonstration-dashboard-kpis">
            <div className="demonstration-dashboard-kpis-item">
              <p className="demonstration-dashboard-kpis-item-title text-accent">
                Nombre d'Alumni SIGL
              </p>
              <p className="demonstration-dashboard-kpis-item-value">
                {siglCount}
              </p>
            </div>
            <div className="demonstration-dashboard-kpis-item">
              <p className="demonstration-dashboard-kpis-item-title text-accent">
                {linkedinProfileCount} Profils Linkedin valides
              </p>
              <div className="demonstration-dashboard-kpis-item-value demonstration-dashboard-kpis-percentage">
                <p>
                  {" "}
                  {((linkedinProfileCount / siglCount) * 100).toFixed(0)}%{" "}
                </p>
                <ProgressBar
                  percentage={(
                    (linkedinProfileCount / siglCount) *
                    100
                  ).toFixed(0)}
                />
              </div>
            </div>
            <div className="demonstration-dashboard-kpis-item">
              <p className="demonstration-dashboard-kpis-item-title text-accent">
                {criProfileCount} Profils CRI valides
              </p>
              <div className="demonstration-dashboard-kpis-item-value demonstration-dashboard-kpis-percentage">
                <p> {((criProfileCount / siglCount) * 100).toFixed(0)}% </p>
                <ProgressBar
                  percentage={((criProfileCount / siglCount) * 100).toFixed(0)}
                />
              </div>
            </div>
            <div className="demonstration-dashboard-kpis-item">
              <p className="demonstration-dashboard-kpis-item-title text-accent">
                {validJobsCount} Jobs de SIGL récupérés
              </p>
              <div className="demonstration-dashboard-kpis-item-value demonstration-dashboard-kpis-percentage">
                <p> {((validJobsCount / siglCount) * 100).toFixed(0)}% </p>
                <ProgressBar
                  percentage={((validJobsCount / siglCount) * 100).toFixed(0)}
                />
              </div>
            </div>
            <div className="demonstration-dashboard-kpis-item">
              <p className="demonstration-dashboard-kpis-item-title text-accent">
                {validCompaniesCount} Entreprises de SIGL récupérées
              </p>
              <div className="demonstration-dashboard-kpis-item-value demonstration-dashboard-kpis-percentage">
                <p> {((validCompaniesCount / siglCount) * 100).toFixed(0)}% </p>
                <ProgressBar
                  percentage={((validCompaniesCount / siglCount) * 100).toFixed(
                    0
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="demonstration-dashboard-intro">
          <h2 className="sub-title text-accent-dark">
            Données sur les Alumni SIGL
          </h2>
          <div className="demonstration-dashboard-sigl">
            <div className="demonstration-dashboard-kpis-item demonstration-dashboard-chart">
              <p className="demonstration-dashboard-kpis-item-title text-accent">
                Postes le plus populaire
              </p>
              <MostPopularJobChart data={mostPopularJob} />
            </div>
            <div className="demonstration-dashboard-kpis-item">
              <p className="demonstration-dashboard-kpis-item-title text-accent">
                Entreprises les plus populaires
              </p>
              <MostPopularCompaniesTable data={mostPopularCompanies} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DemonstrationDashboard;
