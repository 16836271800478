const baseUrl = "https://annuaire.sigl.epita.fr/api"; // Production
const getAllSigl = baseUrl + "/sigls";
const searchSigl = baseUrl + "/sigls/search";
const getOneSigl = (siglId) => `${baseUrl}/sigls/${siglId}`;
const createSigl = baseUrl + "/sigls";
const updateSigl = (siglId) => `${baseUrl}/sigls/${siglId}`;
const deleteSigl = (siglId) => `${baseUrl}/sigls/${siglId}`;

const swagger = baseUrl + "/api-json";
const swaggerDemo = baseUrl + "/api";

const getSiglCount = baseUrl + "/dashboard/sigl-count";
const getLinkedinProfileCount = baseUrl + "/dashboard/linkedin-profile-count";
const getCriProfileCount = baseUrl + "/dashboard/cri-profile-count";
const getValidJobsCount = baseUrl + "/dashboard/valid-jobs-count";
const getValidCompaniesCount = baseUrl + "/dashboard/valid-companies-count";
const getMostPopularJob = baseUrl + "/dashboard/most-popular-jobs";
const getMostPopularCompanies = baseUrl + "/dashboard/most-popular-companies";

export {
  getAllSigl,
  searchSigl,
  getOneSigl,
  createSigl,
  updateSigl,
  deleteSigl,
  swagger,
  getSiglCount,
  getLinkedinProfileCount,
  getCriProfileCount,
  getMostPopularJob,
  getValidJobsCount,
  getValidCompaniesCount,
  getMostPopularCompanies,
  swaggerDemo,
};
