// App.js
import React from "react";
import "./Components/styles/App.css";
import Navbar from "./Components/Navbar";
import HeroSection from "./Sections/HeroSection";
import MainFeaturesSection from "./Sections/MainFeaturesSection";
import DemonstrationAnnuaire from "./Sections/DemonstrationAnnuaire";
import DemonstrationDashboard from "./Sections/DemonstrationDashboard";
import DocumentationDev from "./Sections/DocumentationDev";
import DocumentationOps from "./Sections/DocumentationOps";
import PresentationProjet from "./Sections/PresentationProjet";

function App() {
  return (
    <div className="App">
      <Navbar />
      <HeroSection />
      <MainFeaturesSection />
      <PresentationProjet />
      <DemonstrationAnnuaire />
      <DemonstrationDashboard />
      <DocumentationDev />
      <DocumentationOps />
    </div>
  );
}

export default App;
