/* Présentation du projet should have : 
- Description 
- Notions abordées
- Fonctionnalités
- Technologies utilisées
*/

import React from "react";
import "./styles/PresentationProjet.css";
import DevopsIllustration from "../images/devops.png";
import DevFullStackIllustration from "../images/dev-fullstack.png";
import SuiviDeProjetIllustration from "../images/suivi-de-projet.png";
import DataCollection from "../images/data-scrapping.png";
import FeatureAnnuaireIllustration from "../images/feature-annuaire-illustration.png";
import FeatureDashboardIllustration from "../images/feature-dashboard-illustration.png";
import FeatureEmailIllustration from "../images/feature-email-illustration.png";
import Button from "../Components/Button";

const PresentationProjet = () => {
  return (
    <div className="container">
      <section className="presentation-projet" id="presentation-projet">
        <h2 className="title">Présentation du projet</h2>
        <h3 className="sub-title text-accent">
          3 mois pour livrer le MVP de l'application Annuaire SIGL
        </h3>
        <p className="body-text">
          Le projet Annuaire SIGL est un projet de développement d'une
          application web permettant de rechercher des anciens élèves de l'école
          EPITA.
          <br />
          Il s'agit d'un projet de bout en bout, de la conception à la mise en
          production.
          <br />
        </p>
        <div className="project-presentation-notion">
          <div className="project-presentation-notion-card">
            <img src={SuiviDeProjetIllustration} alt="Suivi de projet" />
            <h3 className="sub-title">
              Suivi de projet
              <br />
              <span className="text-accent-dark">Agile</span>
            </h3>
            <p className="body-text">
              Le projet a été réalisé en méthode agile, avec des sprints de 2
              semaines.
              <br />
              Nous avons utilisé Notion pour le suivi des tâches.
            </p>
          </div>

          <div className="project-presentation-notion-card">
            <img src={DevFullStackIllustration} alt="Dev Full Stack" />
            <h3 className="sub-title">
              Développement Full Stack
              <br />
              <span className="text-accent-dark">React & Node.js</span>
            </h3>
            <p className="body-text">
              Le projet est composé d'une application frontend en React.js et
              d'une API en Node.js.
              <br />
              Nous avons utilisé PostgreSQL pour la base de données.
            </p>
          </div>

          <div className="project-presentation-notion-card">
            <img src={DataCollection} alt="Data Collection" />
            <h3 className="sub-title">
              Collecte de donnée
              <br />
              <span className="text-accent-dark">Linkedin & CRI</span>
            </h3>
            <p className="body-text">
              Nous avons collecté plus de 850 profils SIGL depuis Linkedin et le
              CRI.
              <br />
              Nous avons utilisé Python pour la collecte des données.
            </p>
          </div>

          <div className="project-presentation-notion-card">
            <img src={DevopsIllustration} alt="Devops" />
            <h3 className="sub-title">
              Devops
              <br />
              <span className="text-accent-dark">Container & CI/CD</span>
            </h3>
            <p className="body-text">
              Le projet est déployé sur un serveur dédié.
              <br />
              Nous avons utilisé Docker et Github Actions pour la CI/CD.
            </p>
          </div>
        </div>

        <div className="project-presentation-feature">
          <h3 className="sub-title">
            Les fonctionnalités de l'application Annuaire SIGL
          </h3>
          <div className="project-presentation-feature-card">
            <div className="project-presentation-feature-card-content">
              <h3 className="sub-title">
                Annuaire SIGL
                <br />
                <span className="text-accent-dark">
                  Trouvez les anciens SIGLS
                </span>
              </h3>
              <p className="body-text text-accent">Fonctionnalité en prod !</p>
              <p className="body-text">
                L'application permet de rechercher des anciens élèves depuis sa
                base de donnée de plus de 850 profils.
                <br />
                La recherche peut se faire par nom, entreprise, poste ou
                promotion.
              </p>

              <Button
                text={"Voir l'annuaire"}
                isSeconadry={true}
                link={"#demonstration-annuaire"}
              />
            </div>
            <img src={FeatureAnnuaireIllustration} alt="Annuaire SIGL" />
          </div>

          <div className="project-presentation-feature-card">
            <img src={FeatureEmailIllustration} alt="Email automatique" />
            <div className="project-presentation-feature-card-content">
              <h3 className="sub-title">
                Email automatique
                <br />
                <span className="text-accent-dark">
                  Invitez les anciens SIGLS aux évènements
                </span>
              </h3>
              <p className="body-text text-accent">
                Cette fonctionnalité n'est pas encore disponible
              </p>
              <p className="body-text">
                Nous n'avons pas réussi à mettre en place cette fonctionnalité.{" "}
                <br />
                Nous avons cependant appris qu'il n'était pas possible de
                récupérer les emails des utilisateurs depuis Linkedin.
              </p>
            </div>
          </div>

          <div className="project-presentation-feature-card">
            <div className="project-presentation-feature-card-content">
              <h3 className="sub-title">
                Dashboard Administrateur
                <br />
                <span className="text-accent-dark">
                  Suivez l'évolution du projet
                </span>
              </h3>
              <p className="body-text text-accent">
                Cette fonctionnalité est en prod
              </p>
              <p className="body-text">
                Le dashboard permet de suivre l'évolution du projet. <br />
                Il permet aussi d'apporter de nouvelles informations sur les
                alumnis SIGL et leurs parcours professionnels.
              </p>
              <Button
                isSeconadry={true}
                text={"Voir le dashboard"}
                link={"#demonstration-dashboard"}
              />
            </div>
            <img src={FeatureDashboardIllustration} alt="Dashboard" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default PresentationProjet;
