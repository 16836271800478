import React from "react";
import "./styles/DocumentationDev.css";
import CodeSnippet from "../Components/CodeSnippet";
import ArchitectureProjetSchema from "../images/architecture-projet.png";
import Button from "../Components/Button";
import { swaggerDemo } from "../AnnuaireSiglApi";

const DocumentationDev = () => {
  return (
    <section className="documentation-dev" id="documentation-dev">
      <div className="container">
        <h2 className="title">Documentation pour les Développeurs</h2>
        <div className="documentation-dev-content">
          <div className="documentation-dev-section">
            <h3 className="sub-title text-accent-dark">
              Introduction & Objectifs
            </h3>
            <p className="body-text">
              Ce projet est un module d'annuaire SIGL. Il permet de rechercher
              des anciens élèves dans l'idée de pouvoir les contacter pour des
              évènements ou des oppotunités de réseaux.
            </p>
          </div>
          <div className="documentation-dev-section">
            <h3 className="sub-title text-accent-dark">Installation</h3>
            <p className="body-text">
              1. Assurez-vous d'avoir Docker et Docker Compose installés sur
              votre système.
            </p>
            <p className="body-text">
              2. Clonez le projet depuis le repository SIGL-SIWEB/annuaire
            </p>
            <CodeSnippet
              code={`> git clone git@github.com:SIGL-SIWEB/annuaire.git
> cd service-annuaire-sigl`}
            />
            <p className="body-text">
              3. Démarrage du Projet : Utilisez Docker Compose pour démarrer les
              services du projet en exécutant la commande suivante :
            </p>
            <CodeSnippet code={`> docker-compose up -d`} />
            <p className="body-text">
              Cette commande lancera les conteneurs Docker nécessaires, y
              compris la base de données PostgreSQL, l'API annuaire-sigl et
              l'application frontend.
            </p>
            <ul>
              <li>Le backend est accessible sur le port 1234:1234</li>
              <li>Le frontend est accessible sur le port 8080:3000</li>
              <li>La base de données est accessible sur le port 5432:5432</li>
              <li>PgAdmin est accessible sur le port 5050:80</li>
            </ul>
          </div>

          <div className="documentation-dev-section">
            <div className="two-columns">
              <div className="documentation-dev-section">
                <h3 className="sub-title text-accent-dark">Architecture</h3>
                <p className="body-text">
                  Le projet est composé de 3 services :
                </p>
                <ul>
                  <li>
                    <div>
                      <p className="text-accent">
                        Une API annuaire-sigl. Pour la lancer :
                      </p>
                      <CodeSnippet
                        code={`> cd service-annuaire-sigl
> npm install
> npm run start`}
                      />
                    </div>
                  </li>
                  <li>
                    <div>
                      <p className="text-accent">
                        Une application frontend. Pour la lancer :
                      </p>
                      <CodeSnippet
                        code={`> cd frontend
> npm install
> npm run start`}
                      />
                    </div>
                  </li>
                  <li>
                    <p className="text-accent">
                      Une base de données PostgreSQL.
                    </p>
                    <p className="body-text">
                      Pour la gérer, vous pouvez utiliser PgAdmin. Pour y
                      accéder, rendez-vous sur http://localhost:5050/ et
                      connectez-vous avec les identifiants suivants :
                    </p>
                    <ul className="two-columns">
                      <li>email: admin@admin.com</li>
                      <li>password: pgadmin4</li>
                    </ul>
                    <p className="body-text">
                      Une fois connecté, créez un nouveau serveur avec les
                      informations suivantes :
                    </p>
                    <ul className="two-columns">
                      <li>host: postgres</li>
                      <li>port: 5432</li>
                      <li>username: postgres</li>
                      <li>password: postgres</li>
                    </ul>
                  </li>
                </ul>
                <p className="body-text">
                  Le schéma ci-dessous illustre l'architecture du projet :
                </p>
              </div>
              <img
                src={ArchitectureProjetSchema}
                alt="Architecture du projet"
              />
            </div>
          </div>

          <div className="documentation-dev-section">
            <h3 className="sub-title text-accent-dark">
              Swagger API Documentation
            </h3>
            <p className="body-text">
              La documentation de l'API est disponible sur Swagger
            </p>
            <Button text={"Lire la documentation Swagger"} link={swaggerDemo} />
          </div>

          <div className="documentation-dev-section">
            <h3 className="sub-title text-accent-dark">Tests</h3>
            <p className="body-text">
              Pour lancer les tests, exécutez la commande suivante :
            </p>
            <CodeSnippet
              code={`> cd service-annuaire-sigl
> npm run test`}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DocumentationDev;
