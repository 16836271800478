import React from "react";
import "./styles/Navbar.css";
import Dropdown from "./Dropdown";
const Navbar = () => {
  return (
    <nav>
      <div className="navbar-left">
        <h1>SiWeb - Equivalence 2023</h1>
      </div>
      <div className="navbar-right">
        <ul>
          <li>
            <a href="#presentation-projet">Présentation</a>
          </li>
          <Dropdown title="Documentation">
            <li>
              <a href="#documentation-dev">Pour les Devs</a>
            </li>
            <li>
              <a href="#documentation-ops">Pour les Ops</a>
            </li>
          </Dropdown>
          <Dropdown title="Démonstration">
            <li>
              <a href="#demonstration-annuaire">Module Annuaire</a>
            </li>
            <li>
              <a href="#demonstration-dashboard">Module Dashboard</a>
            </li>
          </Dropdown>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
