// This component is code snippet, it take a code as props and display it in a code block.

import React from "react";
import "./styles/CodeSnippet.css";

const CodeSnippet = ({ code }) => {
  return (
    <div className="code-snippet">
      <pre>
        <code>{code}</code>
      </pre>
    </div>
  );
};

export default CodeSnippet;
