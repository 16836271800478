import React, { useState, useEffect } from "react";
import axios from "axios";
import { getMostPopularCompanies } from "../AnnuaireSiglApi";
import ReactPaginate from "react-paginate";
import "./styles/Pagination.css";

const MostPopularCompaniesTable = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(5);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(getMostPopularCompanies);
        setData(response.data.companies);
      } catch (error) {
        console.error("Error fetching most popular companies:", error);
      }
    };

    fetchData();
  }, []);

  const indexOfLastCompany = currentPage * perPage;
  const indexOfFirstCompany = indexOfLastCompany - perPage;
  const currentCompanies = data.slice(indexOfFirstCompany, indexOfLastCompany);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="most-popular-companies-table">
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="px-4 py-2 w-3/4">Entreprise</th>
            <th className="px-4 py-2 w-1/4">Nombre de SIGL</th>
          </tr>
        </thead>
        <tbody>
          {currentCompanies.map((company) => (
            <tr key={company.company}>
              <td className="border px-4 py-2 w-3/4">{company.company}</td>
              <td className="border px-4 py-2 w-1/4">{company.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactPaginate
        previousLabel={"Précédent"}
        nextLabel={"Suivant"}
        pageCount={Math.ceil(data.length / perPage)}
        onPageChange={({ selected }) => paginate(selected + 1)}
        containerClassName={"pagination"}
        previousLinkClassName={"previousLink"}
        nextLinkClassName={"nextLink"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
    </div>
  );
};

export default MostPopularCompaniesTable;
