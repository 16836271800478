import React from "react";
import "./styles/Button.css";

const Button = ({ text, link, isSeconadry = false }) => {
  const buttonClass = isSeconadry ? "secondary-button" : "primary-button";
  return (
    <a href={link} className={buttonClass}>
      {text}
    </a>
  );
};

export default Button;
