import React, { useState } from "react";
import "./styles/SeachSiglForm.css";
const SeachSiglForm = ({ onSubmit }) => {
  const [name, setName] = useState("");
  const [job, setJob] = useState("");
  const [company, setCompany] = useState("");
  const [promotion, setPromotion] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ name, job, company, promotion });
    console.log({ name, job, company, promotion });
  };

  return (
    <form className="search-sigl-form" onSubmit={handleSubmit}>
      <input
        id="name"
        type="text"
        placeholder="Nom"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        id="job"
        type="text"
        placeholder="Métier"
        value={job}
        onChange={(e) => setJob(e.target.value)}
      />
      <input
        id="company"
        type="text"
        placeholder="Entreprise"
        value={company}
        onChange={(e) => setCompany(e.target.value)}
      />
      <input
        id="promotion"
        type="text"
        placeholder="Promotion SIGL"
        value={promotion}
        onChange={(e) => setPromotion(e.target.value)}
      />
      <button type="submit" onClick={handleSubmit}>
        Rechercher
      </button>
    </form>
  );
};

export default SeachSiglForm;
