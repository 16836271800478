import React, { useState, useEffect } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { getMostPopularJob } from "../AnnuaireSiglApi";

Chart.register(...registerables);

const MostPopularJobChart = () => {
  const [data, setData] = useState({});
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(getMostPopularJob);
        setData(response.data);
        setLabels(response.data.jobs.map((job) => job.job));
        setValues(response.data.jobs.map((job) => job.count));
      } catch (error) {
        console.error("Error fetching most popular job:", error);
      }
    };

    fetchData();
  }, []);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Nombre de SIGL par métier",
        data: values,
        backgroundColor: "#ff715B",
        borderWidth: 0,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        gridLines: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 13,
        },
      },
      y: {
        stacked: true,
        gridLines: {
          color: "#e6e6e6",
          drawBorder: false,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (context) => data.jobs[context[0].dataIndex]?.job,
          label: (context) =>
            `${data.jobs[context.dataIndex]?.count} - ${data.jobs[
              context.dataIndex
            ]?.keywords.join(", ")}`,
        },
      },
    },
  };

  return (
    <div>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default MostPopularJobChart;
